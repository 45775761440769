<template>
  <div>
    <agile
      :autoplay="true"
      :center-mode="true"
      :dots="false"
      :nav-buttons="false"
      :fade="true"
    >
      <div v-for="slide in slides" :key="slide">
        <img class="w-full block object-cover" :style="style" :src="slide" />
      </div>
    </agile>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import _ from 'lodash'
import { useWindowSize } from '@vueuse/core'
import { VueAgile } from 'vue-agile'

import s1 from './s1.jpg'
import s2 from './s2.jpg'
import s3 from './s3.jpg'
import s4 from './s4.jpg'
import s5 from './s5.jpg'
import s6 from './s6.jpg'
import s7 from './s7.jpg'
import s8 from './s8.jpg'
import s9 from './s9.jpg'

export default {
  components: { agile: VueAgile },

  setup() {
    const { width } = useWindowSize()

    const sliderHeight = computed(() =>
      _.round(_.clamp(width.value * 0.2, 320, 640))
    )

    const style = computed(() => ({ height: `${sliderHeight.value}px` }))

    return {
      slides: [s9, s8, s1, s2, s3, s4, s5, s6, s7],
      style,
    }
  },
}
</script>
