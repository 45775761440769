import Rails from '@rails/ujs'
// import Turbolinks from 'turbolinks'
// import * as ActiveStorage from '@rails/activestorage'

require('stylesheets/application')

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

import Vue from 'vue/dist/vue.esm'
import VueCompositionAPI from '@vue/composition-api'
// import TurbolinksAdapter from 'vue-turbolinks'

// Vue.use(TurbolinksAdapter)
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

import Navigation from '../components/navigation'
import Slider from '../components/slider'

// document.addEventListener('turbolinks:load', () => {
document.addEventListener('DOMContentLoaded', () => {
  const hasVue = document.body.contains(
    document.querySelector("[data-behavior='vue']")
  )

  if (hasVue) {
    window.vue = new Vue({
      el: "[data-behavior='vue']",
      // store,
      components: { Navigation, Slider },
    })
  }
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../images', true)
