<template>
  <li>
    <a v-if="!children.length" :href="to" :class="linkClass">
      {{ label }}
    </a>

    <div v-else ref="target" class="relative">
      <a :class="linkClass" @click.prevent="opened = !opened">
        <span>{{ label }}</span>
        <svg
          class="ml-1 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </a>

      <Submenu v-if="opened" :items="children" />
    </div>
  </li>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { onClickOutside } from '@vueuse/core'

import Submenu from './submenu'

export default {
  components: { Submenu },

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: false,
      default: null,
    },
    children: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup({ children, active }) {
    const opened = ref(false)
    const target = ref(null)

    onClickOutside(target, () => (opened.value = false))

    const linkClass = computed(() => ({
      'py-2 my-2 mx-1 font-medium hover:text-gray-900 hover:bg-gray-300 rounded': true,
      'block px-6': !children.length,
      'pl-6 pr-3 cursor-pointer inline-flex items-center': children.length,
      'bg-gray-100 text-gray-900': active,
      'text-gray-900 bg-gray-300': opened.value,
    }))

    return {
      target,
      opened,
      linkClass,
    }
  },
}
</script>
