<template>
  <nav class="max-w-7xl mx-auto">
    <ul class="flex text-white">
      <Item
        v-for="item in tree"
        :key="item.label"
        :to="item.link"
        :label="item.label"
        :children="item.children"
        :active="item.active"
      />
    </ul>
  </nav>
</template>

<script>
import Item from './item'

export default {
  components: { Item },

  props: {
    tree: {
      type: Array,
      required: true,
    },
  },
}
</script>
