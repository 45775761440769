<template>
  <div
    class="absolute z-10 -ml-4 mt-3 transform px-2 sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
  >
    <div
      class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
    >
      <div class="relative grid gap-6 bg-white p-8">
        <a
          v-for="item in items"
          :key="item.label"
          :href="item.link"
          class="-m-3 p-2 flex items-start rounded-lg hover:bg-gray-50"
        >
          <div>
            <p class="text-base font-medium text-gray-900 whitespace-nowrap">
              {{ item.label }}
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
